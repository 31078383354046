import React from 'react';
import NewLayout from '../layout/newLayout';
import NewArticle from '../components/newArticle';
import CtaBanner from "../components/ctaBanner";
import Title from "../components/title";
import SEO from "../components/seo";
import { slice as _slice } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventAnchor from '../components/eventAnchor';
import styles from '../styles/scss/pages/blog.module.scss';

const pageSize = 9;

const filterArticles = function filterArticles(articles) {
    const out = articles.filter(blog => {
        let matchCategory = true;
        return matchCategory;

    });
    return out;
};

const NewBlogTemplate = ({
    pageContext
}) => {


    // States
    const [currentPage, setCurrentPage] = React.useState(0);
    const [articles] = React.useState(() => {
        return pageContext.articles.sort((a, b) => {
            return a.dateOfArticle < b.dateOfArticle ? 1 : -1
        });
    });

    const handleInfiniteScrollNext = React.useCallback(() => {
        setCurrentPage(s => s + 1);
    }, []);

    // Memos
    const {
        pagedArticles,
        pages
    } = React.useMemo(() => {
        const out = {
            pagedArticles: [],
            pages: 0
        };

        if (articles && articles.length) {
            // Filter products
            const filteredArticles = filterArticles(articles);

            // Paginate products
            const paginationStart = currentPage * pageSize;
            const paginationEnd = (currentPage * pageSize) + pageSize;
            const newpagedArticles = _slice(filteredArticles, paginationStart, paginationEnd);
            const previouspagedArticles = _slice(articles, 0, paginationStart);

            out.pagedArticles = currentPage === 0 ? newpagedArticles : [...previouspagedArticles, ...newpagedArticles];

            // Calculate Pages
            out.pages = Math.ceil(articles.length / pageSize);
        }

        return out;
    }, [
        currentPage,
        articles
    ]);

    // Disable SSR on this page
    if (typeof window === 'undefined') {
        return null;
    }
    return (
        <NewLayout>
            <SEO page={pageContext.page} />
            <div className='d-flex flex-column flex-fill'>
                <InfiniteScroll
                    className={styles.infiniteContainer}
                    dataLength={pagedArticles.length}
                    hasMore={currentPage <= (pages - 1)}
                    loader={() => (
                        <h4>
                            Loading...
                        </h4>
                    )}
                    endMessage={
                        <p className={styles.endMessage}>
                            There are no more products
                        </p>
                    }
                >

                    <div className={styles.topContentWrapper}>
                        <div
                            className={styles.topContent}
                        >
                            <Title
                                className={styles.sitetitleNewLayout}
                                value="WHAT'S NEW AT WD-40"
                            />

                        </div>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.items}>
                            {pagedArticles.length > 0 && pagedArticles.map(blog => (
                                <div key={blog.id} className={styles.blogItem}>
                                    <NewArticle
                                        article={blog}
                                        imageClass={styles.itemImage}
                                        bodyClass={styles.itemBody}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </InfiniteScroll>
            </div>

            <div className={styles.seeMoreContainer}>
                {currentPage < (pages - 1) &&
                    <EventAnchor
                        text="See more news >>"
                        event={handleInfiniteScrollNext}
                        condensed
                    />
                }
            </div>

            <CtaBanner
                text="WANT TO GET MORE TIPS AND TRICKS?"
                buttonText="SUBSCRIBE TO THE NEWSLETTER"
                buttonUrl="/signup/"
            />
        </NewLayout>
    )
}

export default React.memo(NewBlogTemplate);